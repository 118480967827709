import {
  listProjects,
  listProjectErrorReset,
} from "@bkry/bowline-redux/projects";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { useLocation } from "react-router-dom";
import { Button, Container, Modal, ModalBody } from "reactstrap";
import Navbar from "../../components/Navbar";
import PendingOrdersAlert from "../../components/PendingOrdersAlert";
import { Sidebar } from "../../components/Sidebar";
import classNames from "classnames";
import { push } from "connected-react-router";

const CreatorLayoutRoute = (
  { component: Component, fluidContainer, sidebarHidden, ...rest },
  context
) => {
  const location = useLocation();
  const [sidebarMinimized, setSidebarMinimized] = useState(false);

  const [mobileSidebarActive, setMobileSidebarActive] = useState(false);
  const toggleMobileSidebar = () =>
    setMobileSidebarActive(!mobileSidebarActive);

  useEffect(() => {
    setMobileSidebarActive(false);
  }, [location]);

  const dispatch = useDispatch();
  const projectId =
    location.pathname.split("/projects/").pop().split("/").length > 0
      ? location.pathname.split("/projects/").pop().split("/")[0]
      : null;
  const projectListError = useSelector((state) => state.projects.listError);
  const creatorProjectIds = useSelector((state) => state.projects.idsList);
  const isCreator = creatorProjectIds.length > 0;

  useEffect(() => {
    dispatch(listProjects());
  }, [dispatch]);

  const handleUnauthorized = () => {
    if (isCreator) {
      dispatch(push(`/projects`));
    } else {
      dispatch(push(`/`));
    }
    dispatch(listProjectErrorReset());
  };

  const isProjectsPath = rest.path === "/projects";

  // if no wallet is connected or wallet is connected but is not a creator of the current project show modal
  if (
    projectListError === "Unauthorized" ||
    (isCreator && !isProjectsPath && !creatorProjectIds.includes(projectId))
  ) {
    return (
      <Modal
        isOpen
        size="xl"
        centered
        className="projectactivationmodal"
        backdropClassName={classNames(
          "projectactivationmodal__backdrop",
          "projectactivationmodal__backdrop--notactivated"
        )}
        scrollable
      >
        <ModalBody className="m-3 m-md-5">
          <h1 className="text-center text-danger">
            {context.t("Your are not authorized to access this page")}
          </h1>
          <p>
            {context.t(
              "Please check if you are connected to the correct wallet and if you are the creator of this project."
            )}
          </p>
          <div className="text-center d-flex justify-content-center">
            <Button
              onClick={handleUnauthorized}
              color="primary"
              size="md"
              className="mx-1"
            >
              {isCreator ? context.t("My Projects") : context.t("Connect")}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
  // if wallet is connected and is a creator of the current project render the component
  if (isProjectsPath || creatorProjectIds.includes(projectId)) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <Navbar
                toggleMobileSidebar={toggleMobileSidebar}
                sidebarMinimized={sidebarMinimized}
              />
              <div className="dashboard">
                {!sidebarHidden && (
                  <Sidebar
                    onCollapse={(sidebarMinimized) => {
                      setSidebarMinimized(sidebarMinimized);
                    }}
                    mobileSidebarActive={mobileSidebarActive}
                  />
                )}
                <div
                  className={cx("dashboardcontent", {
                    "dashboardcontent--sidebarcollapsed": sidebarMinimized,
                    "dashboardcontent--sidebarhidden": sidebarHidden,
                  })}
                >
                  <Container fluid={fluidContainer}>
                    <PendingOrdersAlert />
                    <Component {...props} />
                  </Container>
                </div>
              </div>
            </>
          );
        }}
      />
    );
  } else {
    // TODO: A loading spinner should be shown here
    return "";
  }
};

/**  define proptype for the 'translation' function  */
CreatorLayoutRoute.contextTypes = {
  t: PropTypes.func,
};

CreatorLayoutRoute.propTypes = {
  component: PropTypes.func,
  fluidContainer: PropTypes.bool,
  sidebarHidden: PropTypes.bool,
};

CreatorLayoutRoute.defaultProps = {
  fluidContainer: false,
  sidebarHidden: false,
};

/** export the component as redux connected component */
export default CreatorLayoutRoute;
